import { ProgressController } from "./progress_controller.js"

import Rails from "@rails/ujs"
import * as utilities from "../src/utilities.js"

const QUANTITIES_SELECTOR = '[name="quantities[]"]'
const SELECT_SELECTOR = '#add_line_items'

function setQuantityColor(element){
  const value = element.value;

  if ((value == "") || (parseInt(value) == 0))
    element.style.borderColor = "Red";
  else
    element.style.borderColor = "";
}

function setQuantityColors(){
  document.querySelectorAll(QUANTITIES_SELECTOR).forEach((element)=>setQuantityColor(element));
}

export default class extends ProgressController {

  static targets = ["addLineItemsDescription", "createUpdate", "stateOrProvince", "vendor"]

  initialize(){
    this.addProgressBar("create-update", this.afterCreate);
    super.initialize();
  }

  quantityKeyDown(event){
    const key = event.key;

    if ((key.length == 1) && !key.match(/\d/))
      event.preventDefault();
  }

  quantityInput(event){
    const quantityElements = document.querySelectorAll(QUANTITIES_SELECTOR);

    var total = 0;
    var quantityString, quantity;

    quantityElements.forEach((input)=>{
      quantityString = input.value || "0";
      quantity = parseInt(quantityString);

      setQuantityColor(input);
      total += parseInt(quantityString);
    })

    document.getElementById("total_count").textContent = total.toString();
  }

  addLineItems(event){
    const selectElement = this.addLineItemsDescriptionTarget
    var selection = selectElement.selectedOptions;

    if (selection.length == 0)
      return;

    var formData = new FormData();

    for (var i=0; i<selection.length; i++){
      formData.append("item_type_ids[]", selection[i].value);
      formData.append("descriptions[]", selection[i].textContent);
      formData.append("prices[]", selection[i].dataset.price);
    }

    Rails.ajax({
      type: "POST",
      url: event.params.url,
      data: formData,
      success: this.updateLineItems,
    });
  }

  createUpdate(event){
    const formElement = this.createUpdateTarget

    const formData = new FormData(formElement);

    const progressId = event.target.getAttribute("data-progress-id");
    formData.append("progress_id", progressId);

    if ((this.stateOrProvinceTarget.value == "") || (this.vendorTarget.value == "")){
      alert("Must provide state/province and vendor.");
      return;
    }

    Rails.ajax({
      type: "POST",
      url: formElement.action,
      data: formData,
      success: this.disableForm,
    });
  }

  disableForm(data){
    utilities.enableChildren('div[data-controller="web-shipments"]', false)
  }

  afterCreate(data){
    window.location = data.shipment_url;
  }

  updateLineItems(data){
    const tableBody = document.getElementById("web_shipment_body");
    tableBody.insertAdjacentHTML('beforeend', data.new_item_lines_html);

    setQuantityColors();

    const select = document.querySelector(SELECT_SELECTOR)
    select.innerHTML = data.select_options_html;
    jQuery(SELECT_SELECTOR).selectpicker("refresh");
  }
}
