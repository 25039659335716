/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.
//
// To reference this file, add <%= javascript_pack_tag "application" %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)

console.log("Hello World from Shakapacker")

window.app = {};

require("@rails/ujs")
import "@hotwired/turbo-rails";
require("@rails/activestorage").start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Look for controllers inside app/javascripts/packs/controllers/
const Stimulus = Application.start() 
const controllersContext = require.context("controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(controllersContext))

// require("channels")  // decided to just include them manually so they can export functions if necessary

require("channels/consumer.js")    // initiates the global websocket connection
const packageChannel = require("channels/package_channel.js") // sets up everything to be able to subscribe to the channel when necessary
const userNotificationsChannel = require("channels/user_notifications_channel.js") // sets up everything to be able to subscribe to the channel when necessary

window.jQuery = require("jquery")
require("jquery-ui")
require("jquery-ui/ui/widgets/datepicker")  // require jquery-ui javascript

// require("popper.js")
require("bootstrap")  // require bootstrap javascript
require("stylesheets/application")
require("bootstrap-select")

const receiveShipments = require("src/receive_shipments.js")
const receiveShipmentsShow = require("src/receive_shipments_show.js")
const userNotifications = require("src/user_notifications.js")

import Rails from "@rails/ujs"

function pxFormat(number){
  return (number.toString() + "px");
}

function moveElement(element, pixels, direction){

  if (!["left","right","up","down"].includes(direction)){
    console.log(`Bad direction for moveElement: ${direction}`);
    return;
  }
  
  var offset;

  if ((direction == "up") || (direction == "left"))
    offset = -pixels;
  else
    offset = pixels;

  if ((direction == "left") || (direction == "right")) {
    const newLeft = element.offsetLeft + offset;
    const width = element.offsetWidth;

    element.style.left = pxFormat(newLeft);
    element.style.width = pxFormat(width);
  }
  else {
    const newTop = element.offsetTop + offset;
    const height = element.offsetHeight;

    element.style.top = pxFormat(newTop);
    element.style.height = pxFormat(height);
  }
}

function unmoveElement(element){
  element.style.left   = '';
  element.style.width  = '';
  element.style.top    = '';
  element.style.height = '';
}

function onTurboLoad(event) {  // when turbo loads

  // We have to handle turbo:render in order to catch turbo's handling of 422 responses, which does not trigger
  // a turbo:load event.  However, we don't want to load js every time a render event fires.  In fact, we only
  // want to handle it when our app is recovering from an erroneous form submission.  So, in addition to responding
  // with an unprocessable entity status (422), the server also inserts a div with id unprocessable entity in it.
  // If we receive a turbo:render event without this flag div, we ignore to avoid double load our js and potentially
  // causing problems.

  if ((event.type == "turbo:render") && (document.querySelector("div#unprocessable_entity") == null)){
    return;
  }

  if (document.getElementById("user-notifications-div")){
    userNotificationsChannel.subscribe();
    userNotifications.initialize();
  }

  if ($("#barcode_scanner_div").length) {
    document.addEventListener('keydown', receiveShipments.keydownHandler);
    document.addEventListener('paste', receiveShipments.pasteHandler);
    packageChannel.subscribe();
  }
  else {
    document.removeEventListener('keydown', receiveShipments.keydownHandler);
    packageChannel.unsubscribe();
  }


  if ($("#receive_shipments_show").length)
    receiveShipmentsShow.initialize();
  else
    receiveShipmentsShow.uninitialize();

  // looks for an element of class "datepicker".  If it finds it, it checks
  // for attributes "data-alt-field" and "data-alt-format".  If it finds them,
  // they are passed along as altField (after prepension of '#') and altFormat
  // properties.

  $(".datepicker").each( function( index, text_input ) {    // go through each element of class "datepicker"

    var altField = "";
    var altFormat = "";

    if (text_input.hasAttribute("data-alt-field"))        // check for info on alternate field to support alternate date format
      altField = "#" + text_input.getAttribute("data-alt-field");

    if (text_input.hasAttribute("data-alt-format"))        // check for info on alternate date format
      altFormat = text_input.getAttribute("data-alt-format");

    $(text_input).datepicker({        // modify the edits to be datepickers
      altField: altField,
      altFormat: altFormat,
      dateFormat: "mm/dd/y",
      constrainInput: true,
      changeMonth: true,
      changeYear: true,
      showOtherMonths: true
    });

    if (altField != ""){
      text_input.addEventListener("input", function(e){
        document.querySelector(altField).value = text_input.value;  // for whatever reason, the jquery doesn't do this itself
      });
    }

  });

  $(document).on('hide.bs.dropdown', function(e) {
    return ($('#ui-datepicker-div:visible').length == 0)      // JQUERY DEPENDENCY IN SELECTOR
  });

  $(".dropdown [data-filtering]").each(function(index, dataFilteringElement){    // go through each element of class "datepicker"
    const dropdownDiv = dataFilteringElement.closest("div.dropdown");
    const filterButton = dropdownDiv.querySelector("button.dropdown-toggle")
    filterButton.style.color = "crimson";
  });


  $(".table-responsive .dropdown").on('shown.bs.dropdown', function(e) {

    // reposition the menu if necessary so it doesn't get clipped by the responsive table

    const dropdown = e.currentTarget;
    const table = dropdown.closest(".table-responsive");
    const menu = dropdown.querySelector("ul.dropdown-menu");

    const menuRect = menu.getBoundingClientRect();
    const tableRect = table.getBoundingClientRect();

    if (menuRect.left < tableRect.left)
      moveElement(menu, tableRect.left - menuRect.left, "right");
    else if (menuRect.right > tableRect.right)
      moveElement(menu, menuRect.right - tableRect.right, "left");

    if (menuRect.top < tableRect.top)
      moveElement(menu, tableRect.top - menuRect.top, "down");
    else if (menuRect.bottom > tableRect.bottom)
      moveElement(menu, menuRect.bottom - tableRect.bottom, "up");
  });

  $(".table-responsive .dropdown").on('hidden.bs.dropdown', function(e) {

    // return menu to default position in case it was moved
    const dropdown = e.currentTarget;
    const menu = dropdown.querySelector("ul.dropdown-menu");
    unmoveElement(menu);
  });

  $("select.bootstrap-select").selectpicker();

  $(".textarea-presize").each( function( index, text_area ) {    // go through each element of class "textarea-presize"

    var scrollHeight = text_area.scrollHeight;
    var clientHeight = text_area.clientHeight;

    var style = getComputedStyle(text_area);

    if (scrollHeight > clientHeight) {
      var heightMatch = style.height.match(/^(\d+)px$/);
      var oldHeight = parseInt(heightMatch[1]);
      var newHeight = oldHeight + scrollHeight - clientHeight;

      text_area.style.height = newHeight.toString() + "px";
    }
  });

};

console.log("Adding Event Handlers")

// This is supposed to destroy the datepickers so they aren't
// zombies in the event of a "restoration visit" such as the
// Back button.

// Source: https://github.com/turbolinks/turbolinks/issues/253

// I have to admit that I'm starting to tire of these finicky
// jquery-ui datepickers.  If there's something better, I'm
// ready to move there.  The UI of these is nice, but their
// dependence on jquery and their finicky nature are
// becoming tiresome.  It would be nice if they could be
// torn down and replaced without having to resort to
// unpublished methods and objects.

document.addEventListener("turbo:before-cache", function() {

  $.datepicker.dpDiv.remove();  // remove this from the page before caching, to be restored before rendering.  It's the actual
                                // datepicker element that waits to be displayed in response to a mouse click on the date input

  document.querySelectorAll("input.hasDatepicker").forEach(function(element) {
    $(element).datepicker("destroy");       // remove datepickers so they have to set up next time
  })
})

document.addEventListener("turbo:before-render", function(event) {
  $.datepicker.dpDiv.appendTo(event.detail.newBody)   // append datepicker div before rendering.  Total hack
})

document.addEventListener("turbo:load", onTurboLoad);
document.addEventListener("turbo:render", onTurboLoad);
