import * as utilities from "./utilities.js"

import * as receiveShipmentsShowSubscription from "../channels/receive_shipments_show_channel.js" // sets up everything to be able to subscribe to the channel when necessary

const DATA_DESCRIPTION = 'data-description';

function dataDescriptionSelector(value){
  return `[${DATA_DESCRIPTION}="${value}"]`;
}

const DATA_ATTRIBUTE_NAME = 'data-item-type-id';
const RECEIVED_ITEMS_TEXT_SELECTOR = dataDescriptionSelector('received-items-text');
const SHIPPED_ITEMS_CELL_SELECTOR = dataDescriptionSelector('shipped-items-cell');
const ADD_ITEMS_TEXT_SELECTOR = dataDescriptionSelector('add-items-text');
const ADD_ITEMS_BUTTON_SELECTOR = dataDescriptionSelector('add-items-button');

export function initialize(){

  const div = document.querySelector("#receive_shipments_show");
  const shipment_id = parseInt(div.getAttribute("data-shipment-id"));

  receiveShipmentsShowSubscription.subscribe(shipment_id);

  document.querySelectorAll(RECEIVED_ITEMS_TEXT_SELECTOR).forEach(
    function(receivedItemsTextField){
      receivedItemsTextField.addEventListener('focus', handleReceivedItemsTextFocus);    // to show the add button
      receivedItemsTextField.addEventListener('change', handleReceivedItemsTextChange);  // push new received amount to DB
      receivedItemsTextField.addEventListener('keydown', handleReceivedItemsKeydown);    // to handle tab and enter keys
      addNumberBeforeInputHandler(receivedItemsTextField, false);                       // only allow non-negative integers
    }
  );

  document.querySelectorAll(ADD_ITEMS_TEXT_SELECTOR).forEach( function(addItemsTextInput) {
    addItemsTextInput.addEventListener('focus', handleAddItemsTextFocus);    // to show the add button if necessary
    addItemsTextInput.addEventListener('blur', handleAddItemsTextBlur);    // to hide the add button, unless it has been clicked
    addItemsTextInput.addEventListener('keydown', handleAddItemsTextKeydown);    // to handle tab and enter keys
    addNumberBeforeInputHandler(addItemsTextInput, true);                     // only allow integers
  });

  document.querySelectorAll(ADD_ITEMS_BUTTON_SELECTOR).forEach( function(addItemsButton) {    // go through one or fewer matching elements
    addItemsButton.addEventListener('click', handleAddItemsButtonClick);  // add the items
    addItemsButton.addEventListener('focus', handleAddItemsButtonFocus);  // tells add input text "don't hide me"
  });

  document.querySelector("#confirm_complete").addEventListener('click', handleConfirmCompleteClick);
}

function addNumberBeforeInputHandler(element, allowNegative){

  // this whole construct is to allow us to effectively pass a parameter into the event handler.  Who
  // can guess why it's not part of the library.  Basically this functions variables are visible within
  // the handler, since it's defined inside of this function.  So it acts like it's getting passed in.

  function handleBeforeInput(event){

    if (event.data == null)
      return;

    const input = event.target;
    const currentText = event.target.value;

    const text1 = currentText.slice(0,input.selectionStart);
    const text2 = currentText.slice(input.selectionEnd);

    const newText = text1 + event.data + text2;

    var regex;

    if (allowNegative)      // comes from caller scope
      regex = /^-?[0-9]*$/;
    else
      regex = /^[0-9]*$/;

    if (!newText.match(regex))
      event.preventDefault();
  }

  element.addEventListener('beforeinput', handleBeforeInput);
}

export function uninitialize(){
  receiveShipmentsShowSubscription.unsubscribe();
}

function handleAddItemsButtonFocus(event){

  const rowElements = getRowInfo(event.target).elements;
  const addItemsTextInput = rowElements.addItemsTextInput;

  if (addItemsTextInput.timeoutId != null){
    clearTimeout(addItemsTextInput.timeoutId);
    addItemsTextInput.timeoutId = null;
  }
}


function handleReceivedItemsTextFocus(event){
  const input = event.target;

  input.priorValue = input.value;
  input.select();
}

function handleAddItemsTextFocus(event){
  const input = event.target;

  const button = getRowInfo(input).elements.addItemsButton;
  input.select();
  button.style.visibility = "visible";
}

function handleAddItemsTextBlur(event){

  const addItemsTextInput = event.target;

  if (addItemsTextInput.confirming) {
    addItemsTextInput.confirming = false;
    return;
  }

  const rowElements = getRowInfo(event.target).elements;

  addItemsTextInput.timeoutId = setTimeout(hideButton, 20, rowElements);  // hide the button if we didn't just click it, giving it focus
}

function hideButton(rowElements){

  const text = rowElements.addItemsTextInput;
  const button = rowElements.addItemsButton;

  text.timeoutId = null;

  text.value = "";
  button.style.visibility = "hidden";
}

function handleReceivedItemsTextChange(event){

  const input = event.target;

  const rowData = getRowInfo(input).data;

  if (rowData.received_items > rowData.shipped_items) {
    if (!confirm("Number of received items is greater than the number of shipped items.  Continue?")) {
      input.value = input.priorValue;
      input.focus();
      return;
    }
  }

  window.app.receiveShipmentsShowSubscription.sendSetReceivedItems(rowData);
}

function getRowInfo(element){

  const parentRow = getParentRow(element);

  const elements = {
    shippedItemsCell:       parentRow.querySelector(SHIPPED_ITEMS_CELL_SELECTOR),
    receivedItemsTextInput: parentRow.querySelector(RECEIVED_ITEMS_TEXT_SELECTOR),
    addItemsTextInput:      parentRow.querySelector(ADD_ITEMS_TEXT_SELECTOR),
    addItemsButton:         parentRow.querySelector(ADD_ITEMS_BUTTON_SELECTOR),
  };

  const data = {
    item_type_id:   getItemTypeId(element),
    add_items:      parseInt(elements.addItemsTextInput.value),
    received_items: parseInt(elements.receivedItemsTextInput.value),
    shipped_items:  parseInt(elements.shippedItemsCell.textContent),
  };

  return {elements: elements, data: data};
}

// handles adding items whether it was via button click or keystroke
function handleAddItemsButtonClick(event){

  event.preventDefault();

  const rowInfo = getRowInfo(event.target);
  const rowData = rowInfo.data;
  const rowElements = rowInfo.elements;

  const addItemsTextInput = rowElements.addItemsTextInput;
  const addItemsButton = rowElements.addItemsButton;

  var alertMessage = null, confirmMessage = null;
  var valid = true;

  if ((rowData.add_items == null) || (rowData.add_items == 0))
    alertMessage = "Quantity to add cannot be zero.";
  else if ((rowData.add_items < 0) && (rowData.add_items + rowData.received_items < 0))
    alertMessage = "Removing these items would bring the number of received items below zero.";
  else if ((rowData.add_items > 0) && (rowData.add_items + rowData.received_items > rowData.shipped_items))
    confirmMessage = "Adding these items will bring the number of received items above the number of shipped items.  Continue?";

  if (alertMessage || confirmMessage)     // this is a lockout.  Tells the input to ignore its next blur event.  Somehow an
    addItemsTextInput.confirming = true;  // extraneous one gets generated, and wants to hide the button.  Hopefully portable?

  if (alertMessage != null){
    alert(alertMessage);
    valid = false;
  }
  else if (confirmMessage != null)
    valid = confirm(confirmMessage);

  if (!valid) {
    addItemsTextInput.value = rowData.add_items;
    addItemsTextInput.focus()
  }
  else {
    addItemsTextInput.value = "";
    addItemsTextInput.focus()
    window.app.receiveShipmentsShowSubscription.sendAddReceivedItems(rowData);
  }
}

export function receiveSetReceivedItems(data){

  const received_items = data["received_items"];
  const received_total = data["received_total"];
  const itemTypeId = data["item_type_id"];

  const selector = `${itemTypeIdSelector(itemTypeId)} ${RECEIVED_ITEMS_TEXT_SELECTOR}`; // tr is ancestor with item type id
  const receivedItemsTextField = document.querySelector(selector);

  if (receivedItemsTextField != null)
    receivedItemsTextField.value = received_items;

  const itemsInfoCell = document.querySelector("#items_received_of_shipped");

  const oldText = itemsInfoCell.textContent;
  const match = oldText.match(/(?<received>\d+) of \d+/);

  var oldReceivedText = match.groups.received;
  const newReceivedText = received_total.toString();

  const newText = oldText.replace(oldReceivedText, newReceivedText);

  itemsInfoCell.textContent = newText;

  const itemsReceivedCell = document.querySelector("#items_received_total");
  itemsReceivedCell.textContent = newReceivedText;
}

function handleNavigationKeys(event){

  var down = null;

  switch(event.key){
    case "Enter":
    case "Tab":
      down = !event.shiftKey;
      break;
    case "ArrowUp":
      down = false;
      break;
    case "ArrowDown":
      down = true;
      break;
    default:
      down = null;
      break;
  }

  if (down != null) {
    event.preventDefault();
    focusNextInput(event.target, down);
  }
}

function handleReceivedItemsKeydown(event){
  handleNavigationKeys(event);
}

function handleAddItemsTextKeydown(event){

  if (event.key == "Enter") {
    handleAddItemsButtonClick(event);
    return;
  }

  handleNavigationKeys(event);
}

function focusNextInput(currentInput, down){

  const nextInput = getNextRowElement(currentInput, down)

  if (nextInput != null)
    nextInput.focus();
  else
    currentInput.blur();

  return nextInput;
}

function getItemTypeId(element){

  var idString;

  idString = element.getAttribute(DATA_ATTRIBUTE_NAME);

  if (idString == null)
    idString = getParentRow(element).getAttribute(DATA_ATTRIBUTE_NAME);

  if (idString == null)
    return null;
  else
    return parseInt(idString);

}

function getParentRow(element){
  return element.closest('tr');
}

function itemTypeIdSelector(id){
  return `[${DATA_ATTRIBUTE_NAME}="${id}"]`;
}

function getNextRowElement(sourceElement, down){

  const dataDescription = sourceElement.getAttribute(DATA_DESCRIPTION);
  const parentRow = getParentRow(sourceElement);

  var nextParentRow;

  if (down)
    nextParentRow = parentRow.nextElementSibling;
  else
    nextParentRow = parentRow.previousElementSibling;

  if (nextParentRow == null)
    return null;

  return nextParentRow.querySelector(dataDescriptionSelector(dataDescription));
}

function handleConfirmCompleteClick(event) {

  const regex = /^(?<received>-?\d+) of (?<total>\d+)$/;

  const packagesText = document.getElementById("packages_received_of_shipped").textContent;
  const itemsText = document.getElementById("items_received_of_shipped").textContent;

  const packagesMatch = packagesText.match(regex);
  const itemsMatch = itemsText.match(regex);

  const packagesReceived = parseInt(packagesMatch.groups.received);
  const packagesTotal = parseInt(packagesMatch.groups.total);

  const itemsReceived = parseInt(itemsMatch.groups.received);
  const itemsTotal = parseInt(itemsMatch.groups.total);

  var question = null;
  var complete = true;

  if (packagesReceived < packagesTotal)
    question = "Not all packages have been received.  Confirm complete anyway?";
  else if (itemsReceived < itemsTotal)
    question = "Not all shipped items have been received.  Confirm complete anyway?";

  if (question != null)
    complete = confirm(question);

  if (!complete)
    event.preventDefault();

  return;
}

